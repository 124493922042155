@use '../../styles/partials/global';

.skill {
  background: radial-gradient(ellipse at bottom, #1c2833 100%, #1b2735 0%);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: auto;
  position: relative;

  &__title {
    text-align: center;
    font-family: 'MontserratBL';

    letter-spacing: 2px;
    background: linear-gradient(#ffffff, #38495a);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 60px 0 0 0;
    margin: 0;
    &-letter {
      font-family: 'MontserratT';
    }
    &-hr {
      @include global.sectionTitleHR;
      background: #58bda2;
    }
  }
  &__section-one {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 110px;
    min-height: 110px;
    width: 95vw;
    max-width: 500px;
    margin-top: 60px;
    border-radius: 4px;
    @include global.glowBoxShadow;
    @include global.breakpoint(tablet) {
      height: 150px;
      min-height: 150px;
      width: 85.8vw;
      max-width: 970px;
    }
  }
  &__wrapper {
    display: flex;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    justify-content: space-around;
    padding-top: 10px;
    @include global.breakpoint(tablet) {
      padding-top: 10px;
      max-width: 620px;
      justify-content: space-between;
    }
  }
  &__technical-container,
  &__design-container,
  &__management-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px;
    border-radius: 4px;
    text-decoration: none;
    outline: 0;
    transition: 0.2s all ease-in-out;
    @include global.breakpoint(tablet) {
      padding: 10px;
    }
    &:hover {
      cursor: pointer;
      color: #0aa2e7;
      border-top: 1px solid #0aa2e7;
      border-radius: 0;
    }
    &--active {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #0aa2e7;
      border-top: 1px solid silver;
      padding: 10px 7px;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      border-radius: 0;
      outline: 0;
      -moz-outline-style: none;
      text-decoration: none;
      @include global.breakpoint(tablet) {
        padding: 10px;
      }
    }
  }
  &__technical-icon,
  &__design-icon,
  &__management-icon {
    width: 15px;
    height: 15px;
    margin-right: 3px;
    stroke: #bdc3c7;
    fill: none;
    @include global.breakpoint(tablet) {
      width: 25px;
      height: 25px;
    }
  }
  &__technical-description--active,
  &__design-description--active,
  &__management-description--active {
    font-family: 'RalewayR';
    font-size: 13px;
    background: linear-gradient(#ffffff, #38495a);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    @include global.breakpoint(tablet) {
      font-size: 16px;
    }
  }
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__icons-color {
    margin: 0 2.5px;
    fill: #a9a6b6;
    width: 32px;
    height: 32px;
    transition: 0.25s all ease-in-out;

    &:hover {
      fill: url(#gradient);
      filter: drop-shadow(0.35px 3.35px 3px #0aa2e7);
      cursor: pointer;
      fill: #a9a6b6;
    }
    &--active {
      margin: 0 2.5px;
      fill: url(#gradient);
      width: 32px;
      height: 32px;
      filter: drop-shadow(0.35px 3.35px 3px #0aa2e7);
      cursor: pointer;
    }
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    padding: 5px 0 10px 0;
    @include global.skillScrollbarStyle;

    @include global.breakpoint(tablet) {
      padding: 5px 0 15px 0;
      justify-content: center;
    }
  }
  &__section-two {
    font-family: 'RalewayR';
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95vw;
    max-width: 500px;
    height: 580px;
    min-height: 580px;
    margin-top: 10px;
    margin-bottom: 70px;
    border-radius: 4px;
    @include global.glowBoxShadow;
    @include global.breakpoint(tablet) {
      width: 85.8vw;
      max-width: 970px;
      height: auto;
      min-height: auto;
    }
  }
  &__wrapper-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 580px;
    min-height: 580px;
    letter-spacing: 1px;
    @include global.breakpoint(tablet) {
      flex-direction: row;
      justify-content: space-around;
      height: 500px;
      min-height: 500px;
    }
  }
  &__stars,
  &__stars-two,
  &__stars-three {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__stars {
    @include global.starsOne;

    &:after {
      @include global.starsOneAfter;
    }
  }
  &__stars-two {
    @include global.starsTwo;

    &:after {
      @include global.starsTwoAfter;
    }
  }
  &__stars-three {
    @include global.starsThree;

    &:after {
      @include global.starsThreeAfter;
    }
  }
}
