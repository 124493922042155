@use '../../styles/partials/global';

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__form-container {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  &__form {
    width: 90%;
    max-width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    &-name,
    &-email,
    &-subject,
    &-message {
      @include global.formInputStyle;
      margin-bottom: 20px;
      transition: 250ms cubic-bezier(0.39, 0.575, 0.565, 1);
      resize: vertical;
      &:hover,
      &:focus {
        box-shadow: 0 0 3pt 2pt rgba(93, 173, 226, 0.9);
      }
      &-error {
        @include global.formInputStyle;
        margin: 1.5px 0;
        box-shadow: 0 0 0pt 1pt rgba(227, 27, 109, 0.9);
      }
    }
  }
  &__error-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
  }
  &__error-name,
  &__error-email,
  &__error-subject,
  &__error-message {
    font-family: 'RalewayT';
    font-size: 14px;
    letter-spacing: 1px;
    background-color: hsl(335, 59%, 45%);
    color: #ffffff;
    width: fit-content;
    max-width: 500px;
    margin: 0 0 16px;
    padding: 5px;
    border-radius: 4px;
    resize: vertical;
    @include global.breakpoint(tablet) {
      font-size: 15px;
    }
  }
  &__success-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
  }
  &__success-message {
    font-family: 'RalewayT';
    font-size: 14px;
    letter-spacing: 1px;
    background-color: hsl(164, 59%, 35%);
    color: #ffffff;
    width: fit-content;
    max-width: 500px;
    margin: 0 0 16px;
    padding: 5px;
    border-radius: 4px;
    @include global.breakpoint(tablet) {
      font-size: 15px;
    }
  }
  &__form-button {
    font-family: 'RalewayR';
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    width: 150px;
    padding: 15px;
    margin: 10px 0 0 0;
    border-radius: 3px;
    border: 1px solid #ffffff;
    outline: 0;
    background-color: transparent;
    transition: 250ms cubic-bezier(0.39, 0.575, 0.565, 1);
    color: #ffffff;
    &:hover {
      cursor: pointer;
      border: 1px solid transparent;
      background-color: #ffffff;
      color: #0aa2e7;
      box-shadow: 0 0 3pt 2pt rgba(93, 173, 226, 0.9);
    }
    &-disabled {
      font-family: 'RalewayR';
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 2px;
      width: 150px;
      padding: 15px;
      margin: 10px 0 0 0;
      border-radius: 3px;
      border: 0;
      cursor: not-allowed;
    }
  }
}
