@use '../../styles/partials/global';

.scrollBtn {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 165px;
  z-index: 2;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  filter: drop-shadow(0px 0px 0.15rem global.$charcoal);
  cursor: pointer;
  &__icon {
    transform: rotate(180deg);
  }
}
