@use '../../styles/partials/global';

.contact-container {
  position: relative;
  padding: 0 18px;
  background: linear-gradient(
      217deg,
      rgba(10, 162, 231, 1),
      rgba(227, 27, 109, 0) 70.71%
    ),
    linear-gradient(336deg, rgba(76, 243, 198, 1), rgba(227, 27, 109, 1) 90.71%),
    linear-gradient(
      336deg,
      rgba(227, 27, 109, 1),
      rgba(227, 27, 109, 0.5) 90.71%
    );
  box-shadow: inset 0 0 10px #000000;
  overflow: hidden;
  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0 20px 0;
  }
  &__title {
    font-family: 'MontserratT';
    letter-spacing: 2px;
    margin: 0;
    color: #ffffff;
    &-letter {
      font-family: 'MontserratBL';
    }
    &-hr {
      @include global.sectionTitleHR;
      background: #ffffff;
    }
  }
  &__text {
    font-family: 'MontserratSB';
    display: flex;
    flex-direction: column;
    color: #444649;
    font-size: 15px;
    text-align: center;
    line-height: 16px;
    &-highlight {
      font-family: 'RalewayB';
      margin-top: 50px;
      font-size: 25px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}
