@mixin cardHR {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  height: 3px;
  border: none;
  border-radius: 6px;
  margin: 5px auto;
  @content;
}

@mixin projectCardButton {
  font-family: 'RalewaySB';
  position: absolute;
  text-decoration: none;
  padding: 15px;
  top: 339px;
  left: 22px;
  border-radius: 3px;
  font-weight: 600;
  @content;
}

@mixin sectionTitleHR {
  height: 3px;
  border: none;
  border-radius: 6px;
  width: 60px;
  @content;
}

@mixin glowBoxShadow {
  background: rgba(15, 32, 39, 0.7); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    rgba(44, 83, 100, 0.7),
    rgba(32, 58, 67, 0.7),
    rgba(15, 32, 39, 0.7)
  ); /* Chrome 10-25, Safari 6.1-6 */
  background: linear-gradient(
    to right,
    rgba(44, 83, 100, 0.7),
    rgba(32, 58, 67, 0.7),
    rgba(15, 32, 39, 0.7)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 0 3px 3px rgb(126 15 255 / 20%),
    0px 0px 20px rgb(15 255 193 / 20%);
  @supports (backdrop-filter: blur(2px)) {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
}

@mixin appProgressBar {
  background: #ee0979; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff6a00,
    #ee0979
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff6a00,
    #ee0979
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@mixin formInputStyle {
  font-family: 'RalewayR';
  letter-spacing: 1px;
  width: 100%;
  max-width: 500px;
  background-color: #ffffff;
  color: #444649;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 3px;
}

@mixin scrollbarStyle {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(76, 243, 198, 0.8);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background-color: #777;
  }
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin bodyScrollbarStyle {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(76, 243, 198, 0.8);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background-color: #777;
  }
  // Firefox support
  @supports (scrollbar-color: rgba(76, 243, 198, 0.8) #777) {
    scrollbar-color: rgba(76, 243, 198, 0.8) #777;
    scrollbar-width: 8px;
  }
}

@mixin skillScrollbarStyle {
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(76, 243, 198, 0.6);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  // Firefox support
  @supports (scrollbar-color: rgba(76, 243, 198, 0.6) none) {
    scrollbar-color: rgba(76, 243, 198, 0.6) none;
    scrollbar-height: 4px;
  }
}
@mixin featuredScrollbarStyle {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(76, 243, 198, 0.6);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  // Firefox support
  @supports (scrollbar-color: rgba(76, 243, 198, 0.6) none) {
    scrollbar-color: rgba(76, 243, 198, 0.6) none;
    scrollbar-height: 4px;
  }
}
