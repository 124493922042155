@use '../../../styles/partials/global';

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 1000px;
  flex-wrap: wrap;

  &__con {
    height: 400px;
    width: 300px;
    position: relative;
    margin: 15px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    perspective: 800;
    @supports (backdrop-filter: blur(2px)) {
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
    }
  }
  &__card {
    @include global.glowBoxShadow;
    // setting blur support to none to avoid breaking card style, and placing blur support to class '&__con' at line 21 instead.
    @supports (backdrop-filter: none) {
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
    }
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    position: relative;
    transition: all 1.5s ease;
    border-radius: 3px;
    &:hover {
      transform: rotateY(180deg);
      box-shadow: 0 20px 20px rgba(15, 255, 193, 0.2),
        0px 0px 50px rgba(126, 15, 255, 0.2);
    }
  }
  &__back {
    height: 400px;
    background: radial-gradient(ellipse at bottom, #1b2735 0, #1c2833 100%);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
    position: relative;
    bottom: 203px;
    top: -400px;
    border-radius: 3px;
    transform: rotateY(180deg);
  }
  &__front {
    height: 400px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__front,
  &__back {
    backface-visibility: hidden;
  }
  &__name {
    font-family: 'MontserratBL';
    text-transform: uppercase;
    background: linear-gradient(#ffffff, #38495a);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    font-size: 23px;
    margin: 23px 0 0;
    line-height: 30px;
    text-align: left;
  }
  &__card-hr-fitrack {
    @include global.cardHR;
    background: #e31b6d;
  }
  &__card-hr-disneyplus {
    @include global.cardHR;
    background: #58bda2;
  }
  &__card-hr-travelog {
    @include global.cardHR;
    background: #0aa2e7;
  }
  &__card-hr-covid {
    @include global.cardHR;
    background: #0aa2e7;
  }
  &__image-front {
    width: 300px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 10px 19px -4px rgba(0, 0, 0, 0.5);
    object-fit: cover;
    object-position: top;
    height: 320px;
  }
  &__image-back {
    width: 200px;
    height: 300px;
  }
  &__description {
    font-family: 'RalewayR';
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 30px;
    background: linear-gradient(#ffffff, #c4c4c4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    letter-spacing: 1px;
    height: 350px;
    padding: 25px;
    margin: 0;
  }
  &__icons {
    position: relative;
    top: -120px;
    display: flex;
    justify-content: center;
    &-color {
      margin: 0 2.5px;
      fill: #a9a6b6;
      transition: 0.25s all ease-in-out;
      &:hover {
        fill: url(#gradient);
        filter: drop-shadow(0.35px 3.35px 3px #0aa2e7);
      }
    }
  }
  &__inprocess {
    font-family: 'RalewayR';
    font-weight: 900;
    color: #a93226;
    position: absolute;
    top: 250px;
    left: 53px;
  }
  &__demo {
    @include global.projectCardButton;
    border: 1px solid #4cf3c6;
    color: #4cf3c6;

    &:hover {
      background-color: #4cf3c6;
      color: #ffffff;
      font-weight: 600;
      cursor: pointer;
    }
  }
  &__code {
    @include global.projectCardButton;
    border: 1px solid #0aa2e7;
    color: #0aa2e7;
    left: 159px;
    &:hover {
      background-color: #0aa2e7;
      color: #ffffff;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
