@use '../../../styles/partials/global';

.nav-container {
  height: 95px;
  // position: sticky;
  position: fixed;
  width: 100%;
  background: none;
  top: 0;
  z-index: 4;
  backdrop-filter: blur(3px);

  &__nav {
    height: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: none;
    @include global.breakpoint(desktop) {
      display: block;
      width: 100%;
      background: linear-gradient(
          217deg,
          rgba(10, 162, 231, 1),
          rgba(10, 162, 231, 0) 70.71%
        ),
        linear-gradient(
          336deg,
          rgba(10, 162, 231, 1),
          rgba(76, 243, 198, 0.4) 90.71%
        );
      box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.8);
    }
  }
  &__nav-active {
    background: linear-gradient(
        217deg,
        rgba(10, 162, 231, 1),
        rgba(10, 162, 231, 0) 70.71%
      ),
      linear-gradient(
        336deg,
        rgba(10, 162, 231, 1),
        rgba(76, 243, 198, 0.4) 90.71%
      );

    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.8);
    @include global.breakpoint(desktop) {
      width: 100%;
    }
  }
  &__logo-container {
    height: 95px;
    display: flex;
    align-items: center;
    @include global.breakpoint(desktop) {
      justify-content: space-evenly;
      margin-right: 1160px;
    }
  }
  &__logo {
    position: sticky;
    z-index: 4;
    width: 48.5px;
    height: 50px;
    border-radius: 4px;
    margin-left: 14px;
    border: none;
    width: 87px;
    height: 52px;
    cursor: pointer;
  }
}
