@use '../../styles/partials/global';

.home-container {
  overflow: hidden;
  background: radial-gradient(ellipse at bottom, #203a43 0%, #0f2027 100%);
  position: relative;
  &__hero-image {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include global.breakpoint(tablet) {
      margin: 0 0 300px 0;
    }
    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      font-size: 45px;
      letter-spacing: 4px;
      color: global.$graphite;
      @include global.breakpoint(tablet) {
        margin: 310px 0 0 0;
        font-size: 93px;
      }
    }
    &-description {
      font-family: 'RalewayB';
      color: #e31b6d;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      font-weight: 600;
      font-size: 17px;
      letter-spacing: 1.5px;
      filter: drop-shadow(0.4rem 0.6rem 0.1rem rgba(227, 27, 109, 0.2));
      transition: filter 600ms;
      &:hover {
        filter: drop-shadow(0.2rem 0.3rem 0.1rem rgba(227, 27, 109, 0.8));
      }
      @include global.breakpoint(tablet) {
        font-size: 27px;
        font-style: normal;
      }
      &-span {
        margin: 0 12px 0 0;
      }
    }
  }
  &__first-name {
    font-family: 'MontserratBL';
    text-transform: uppercase;
    background: linear-gradient(#ffffff, #38495a);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0.4rem 0.6rem 0.1rem rgba(86, 255, 255, 0.2));
    transition: filter 600ms;
    &:hover {
      filter: drop-shadow(0.2rem 0.3rem 0.1rem rgba(86, 255, 255, 0.8));
    }
  }
  &__last-name {
    font-family: 'MontserratBL';
    text-transform: uppercase;
    background: linear-gradient(#ffffff, #38495a);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.5;
    filter: drop-shadow(0.4rem 0.6rem 0.1rem rgba(10, 162, 231, 0.2));
    transition: filter 600ms;
    display: flex;
    width: 260px;
    height: 35px;
    align-items: center;
    justify-content: center;
    @include global.breakpoint(tablet) {
      height: 88px;
      width: 515px;
    }
    &:hover {
      filter: drop-shadow(0.2rem 0.3rem 0.1rem rgba(10, 162, 231, 0.8));
    }
  }
  &__down-container {
    display: flex;
    justify-content: center;
    outline: 0;
    &:active,
    &:focus {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }
  }
  &__stars,
  &__stars-two,
  &__stars-three {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__stars {
    @include global.starsOne;

    &:after {
      @include global.starsOneAfter;
    }
  }
  &__stars-two {
    @include global.starsTwo;

    &:after {
      @include global.starsTwoAfter;
    }
  }
  &__stars-three {
    @include global.starsThree;

    &:after {
      @include global.starsThreeAfter;
    }
  }
}

#home {
  overflow: hidden;
}
