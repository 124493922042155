@use '../../../../styles/partials/global';

.burger-container {
  &__menu-btn {
    position: absolute;
    // top: 5%;
    top: 8%;
    right: 3%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    cursor: pointer;

    @include global.breakpoint(desktop) {
      display: none;
    }
  }
  &__burger,
  &__burger-center {
    width: 50px;
    height: 6px;
    background-color: #444649;
    border-radius: 5px;
  }
  &__burger-navbar {
    width: 50px;
    height: 6px;
    background-color: global.$light-grey;
    border-radius: 5px;
  }
  &__burger-center {
    margin: 15px 0 15px 0;
    background-color: #e31b6d;
  }
}
