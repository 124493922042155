@use './styles/partials/global';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'RalewayR', 'MontserratR';
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  background: radial-gradient(ellipse at bottom, #1c2833 100%, #1b2735 0%);
  @include global.bodyScrollbarStyle;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #444649;
}
a {
  color: #444649;
}
.active {
  color: #ffffff;
}
