@use '../../styles/partials/global';

.work-container {
  position: relative;
  overflow: hidden;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #1c2833 100%);
  &__work-labels-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 50px 0 20px 0;
    &-sub {
      @include global.glowBoxShadow;
      max-width: 480px;
      border-radius: 4px;
      width: 89%;
      padding: 10px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      @include global.breakpoint(tablet) {
        width: 83vw;
        max-width: 940px;
      }
    }
  }
  &__work-labels {
    font-family: 'RalewayR';
    background: linear-gradient(#ffffff, #c4c4c4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 13px;
    text-decoration: none;
    border-radius: 3px;
    padding: 15px 5px;
    outline: 0;
    transition: 0.2s all ease-in-out;
    &:hover {
      border-top: 1px solid #0aa2e7;
      border-radius: 0;
    }

    @include global.breakpoint(tablet) {
      font-size: 16px;
    }
    &--active {
      font-family: 'RalewaySB';
      font-size: 13px;
      text-decoration: none;
      background: linear-gradient(#ffffff, #c4c4c4);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 15px 5px;
      border-top: 1px solid silver;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      border-radius: 0;
      outline: 0;
      transition: 0.2s all ease-in-out;
      -moz-outline-style: none;
      @include global.breakpoint(tablet) {
        font-size: 16px;
      }
    }
  }
  &__title {
    text-align: center;
    font-family: 'MontserratT';
    letter-spacing: 2px;
    background: linear-gradient(#ffffff, #38495a);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 60px 0 0 0;
    margin: 0;
    &-letter {
      font-family: 'MontserratBL';
    }
    &-hr {
      @include global.sectionTitleHR;
      background: #58bda2;
    }
  }
  &__cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 430px;
    padding: 0 0 70px 0;
  }
  &__stars,
  &__stars-two,
  &__stars-three {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__stars {
    @include global.starsOne;

    &:after {
      @include global.starsOneAfter;
    }
  }
  &__stars-two {
    @include global.starsTwo;

    &:after {
      @include global.starsTwoAfter;
    }
  }
  &__stars-three {
    @include global.starsThree;

    &:after {
      @include global.starsThreeAfter;
    }
  }
}
