@use '../../styles/partials/global';
.about-container {
  background: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
    @include global.breakpoint(tablet) {
      margin: 0 0 60px 0;
      padding: 60px 0 0 0;
    }
  }
  &__title {
    font-family: 'MontserratT';
    letter-spacing: 2px;
    margin: 0;
    color: #444649;
    &-letter {
      font-family: 'MontserratBL';
      color: #5cc2a7;
    }
    &-hr {
      @include global.sectionTitleHR;
      background: #0aa2e7;
    }
  }
  &__column-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    @include global.breakpoint(tablet) {
      flex-direction: row;
      width: 90%;
      margin-bottom: 70px;
    }
  }
  &__profile-container {
    position: relative;
    height: 250px;
    @include global.breakpoint(tablet) {
      margin: 0 40px;
    }
  }
  &__profile {
    margin: 0 15px 0 0;
    width: 274.953px;
    height: 238px;
    position: relative;
    z-index: 2;
    &-background {
      position: absolute;
      height: 299px;
      top: -16px;
      left: 15px;
      bottom: -320px;
      transform: rotate(1.1deg);
    }
  }
  &__profile-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include global.breakpoint(tablet) {
      align-self: center;
      width: 290px;
      margin: 110px 10px;
    }
  }
  &__version-filter-container {
    display: flex;
    width: 90%;
    max-width: 290px;
    justify-content: space-between;
    margin: 50px 0 20px;
    @include global.breakpoint(tablet) {
      margin: 0 0 20px;
      width: 100%;
      justify-content: center;
    }
  }
  &__version-filter {
    font-family: 'RalewaySB';
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: #444649;
    border: 1px solid #444649;
    flex: 1;
    text-align: center;
    letter-spacing: 1px;
    padding: 16px 0;
    margin: 0 5px;
    border-radius: 4px;
    outline: 0;
    transition: 0.25s all ease;
    @include global.breakpoint(tablet) {
      font-size: 16px;
    }
    &:hover {
      color: #0aa2e7;
      border: 1px solid #0aa2e7;
    }
    &--active {
      font-family: 'RalewaySB';
      font-size: 15px;
      text-transform: uppercase;
      color: #0aa2e7;
      text-decoration: none;
      flex: 1;
      text-align: center;
      letter-spacing: 1px;
      padding: 16px 0;
      margin: 0 5px;
      border-top: 1px solid #0aa2e7;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      outline: 0;
      -moz-outline-style: none;
      outline-style: 0;
      transition: 0.2s all ease;
      @include global.breakpoint(tablet) {
        font-size: 16px;
      }
    }
  }
  &__profile-text-animation-container {
    position: relative;
    overflow: hidden;
    padding-top: 25px;
    padding-bottom: 35px;
    width: 320px;
    @include global.breakpoint(tablet) {
      width: 652px;
      padding: 0;
      margin: 100px 320px 0 0;
    }
  }
  &__profile-text-wrapper {
    position: absolute;
    width: 320px;

    @include global.breakpoint(tablet) {
      display: flex;
      align-items: baseline;
      width: 652px;
    }
  }
  &__profile-text-wrapper-relative {
    @include global.breakpoint(tablet) {
      display: flex;
      align-items: baseline;
      position: relative;
    }
  }
  &__profile-text {
    font-family: 'MontserratR';
    font-size: 15px;
    color: #444649;
    letter-spacing: 0px;
    line-height: 25px;
    width: 290px;
    margin: 0 18px 35px;
    @include global.breakpoint(tablet) {
      margin: 25px 18px 35px;
    }

    &-career {
      font-family: 'RalewayR';
      font-size: 16px;
      color: #e31b6d;
      background: rgba(192, 192, 192, 0.4);
      border-radius: 4px;
      padding: 5px;
      margin: 0 3px;
    }
    &-bottom {
      font-family: 'MontserratR';
      font-size: 15px;
      color: #444649;
      letter-spacing: 0;
      line-height: 25px;
      width: 290px;
      margin: 0 18px 0;
      @include global.breakpoint(tablet) {
        margin: 0 18px 35px;
      }
    }
  }
  &__profile-resume-link {
    font-family: 'MontserratSB';
    text-transform: uppercase;
    position: relative;
    text-decoration: none;
    color: #0aa2e7;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    &:before {
      content: '';
      height: 2px;
      background: #0aa2e7;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      opacity: 0;
      transform: scaleX(0);
      transform-origin: left center;
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    }
    &:hover {
      &:before {
        transform: scaleX(1);
        opacity: 1;
      }
    }
    &:hover {
      cursor: pointer;
      color: #0aa2e7;
    }
  }
  &__hexagon-top {
    display: none;
    @include global.breakpoint(tablet) {
      display: flex;
      position: absolute;
      height: 370px;
      top: -91px;
      right: -50px;
      transform: rotate(90deg);
      overflow: hidden;
    }
  }
  &__hexagon-bottom {
    display: none;
    @include global.breakpoint(tablet) {
      display: flex;
      position: absolute;
      height: 370px;
      bottom: -151px;
      left: -30px;
      transform: rotate(270deg);
      overflow: hidden;
    }
  }
}
