@mixin breakpoint($device) {
  @if $device == mobile {
    @media screen and (min-width: 320px) {
      @content;
    }
  } @else if $device == tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $device == desktop {
    @media screen and (min-width: 1280px) {
      @content;
    }
  } @else {
    @warn 'Breakpoint mixin supports mobile @ 320px - 767px, tablet @768px - 1279px, and desktop from 1280px';
  }
}
