@use '../../styles/partials/global';

#footer {
  display: flex;
  justify-content: center;
  background-color: #252525;
}

.footer-container {
  font-family: 'RalewayR';
  background: #252525;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  align-items: center;
  padding: 0 0 30px 0;
  max-width: 1400px;
  &__sub-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  &__copyright-container {
    display: flex;
    justify-content: center;
  }
  &__copyright {
    color: #dce0e5;
    font-size: 12px;
    letter-spacing: 1px;
    margin: 0;
    padding: 80px 0 0 0;
    text-align: center;
    width: 77%;
    max-width: 430px;
    @include global.breakpoint(desktop) {
      width: 100%;
      max-width: 455px;
    }
    &-span {
      font-family: 'RalewayR';
      color: #e31b6d;
    }
  }
  &__social-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 220px;
    padding: 0;
    margin: 20px 0;
    list-style-type: none;
    background-color: #252525;
    &-linkedin {
      fill: #ffffff;
      &:hover {
        fill: #ffffff;
      }
    }
    &-twitter {
      fill: #ffffff;
      &:hover {
        fill: #1d9bf0;
      }
    }
    &-github {
      fill: #ffffff;
      &:hover {
        fill: silver;
      }
    }
    &-download {
      stroke: #ffffff;
      &:hover {
        stroke: #e31b6d;
      }
    }
  }
  &__social-list-link {
    display: flex;
  }
  &__arrow-container {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 3%;
    right: 5%;
    &:active,
    &:focus {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }
  }
  &__go-to-top {
    cursor: pointer;
    transform: rotate(180deg);
    fill: #ffffff;
    transition: all 250ms ease-out;
    outline: 0;
    &:hover {
      fill: url(#arrow);
      filter: drop-shadow(0.35px 3.35px 3px #ffffff);
    }
    &:active,
    &:focus {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }
    @include global.breakpoint(mobile) {
      position: absolute;
      inset: 0;
    }
  }

  &__github-stat-container {
    display: flex;
    justify-content: space-between;
    width: 70px;
  }
  &__star,
  &__forked {
    fill: #ffffff;
    margin-right: 5px;
  }
  &__github-star-span,
  &__github-forked-span {
    color: #ffffff;
  }
}
