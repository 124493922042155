@use '../../styles/partials/global';

.circle {
  @include global.glowBoxShadow;
  font-family: 'MontserratSB';
  width: 60px;
  height: 60px;
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  @include global.breakpoint(tablet) {
    width: 80px;
    height: 80px;
  }
  &__one,
  &__two,
  &__three {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    @include global.breakpoint(tablet) {
      width: 5px;
      height: 5px;
    }
  }
  &__one {
    background: darkslategray;
  }
  &__two {
    background: lightslategray;
    margin: 3px 0;
  }
  &__three {
    background: #f8f8ff;
  }
  &__text-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__text {
    margin: 0;
  }
  &__span {
    position: absolute;
    top: -40%;
    left: 50%;
    font-size: 10px;
    transform-origin: 0 55px;
    -webkit-transform-origin: 0 55px;
    background: linear-gradient(180deg, #ffffff, #38495a);
    background: -webkit-linear-gradient(145deg, #ffffff, #38495a);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    @include global.breakpoint(tablet) {
      top: -35%;
      font-size: 12px;
      transform-origin: 0 70px;
      -webkit-transform-origin: 0 70px;
    }
  }
}
