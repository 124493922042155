@mixin starsThree {
  border-radius: 100%;
  width: 3px;
  height: 3px;
  background: transparent;
  animation: animStar 150s linear infinite;
  box-shadow: 387px 1878px #fff, 760px 1564px #fff, 1487px 999px #fff,
    948px 1828px #fff, 1977px 1001px #fff, 1284px 1963px #fff, 656px 284px #fff,
    1268px 1635px #fff, 1820px 598px #fff, 642px 1900px #fff, 296px 57px #fff,
    921px 1620px #fff, 476px 1858px #fff, 658px 613px #fff, 1171px 1363px #fff,
    1419px 283px #fff, 1037px 731px #fff, 503px 663px #fff, 1562px 463px #fff,
    383px 1197px #fff, 1171px 1233px #fff, 876px 1768px #fff, 856px 1615px #fff,
    1375px 1924px #fff, 1725px 918px #fff, 952px 119px #fff, 768px 1212px #fff,
    992px 1462px #fff, 1929px 717px #fff, 1947px 755px #fff, 1818px 1123px #fff,
    1896px 1672px #fff, 460px 198px #fff, 256px 271px #fff, 752px 544px #fff,
    1222px 1859px #fff, 1851px 443px #fff, 313px 1858px #fff, 709px 446px #fff,
    1546px 697px #fff, 674px 1155px #fff, 1112px 130px #fff, 355px 1790px #fff,
    1496px 974px #fff, 1696px 480px #fff, 1316px 1265px #fff, 1645px 1063px #fff,
    1182px 237px #fff, 427px 1582px #fff, 859px 253px #fff, 458px 939px #fff,
    1517px 1644px #fff, 1943px 60px #fff, 212px 1650px #fff, 966px 1786px #fff,
    473px 712px #fff, 130px 76px #fff, 1417px 1186px #fff, 909px 1580px #fff,
    1913px 762px #fff, 204px 1143px #fff, 1998px 1057px #fff, 1468px 1301px #fff,
    144px 1676px #fff, 21px 1601px #fff, 382px 1362px #fff, 912px 753px #fff,
    1488px 1405px #fff, 802px 156px #fff, 174px 550px #fff, 338px 1366px #fff,
    1197px 774px #fff, 602px 486px #fff, 682px 1877px #fff, 348px 1503px #fff,
    407px 1139px #fff, 950px 1400px #fff, 922px 1139px #fff, 1697px 293px #fff,
    1238px 1281px #fff, 1038px 1197px #fff, 376px 1889px #fff,
    1255px 1680px #fff, 1008px 1316px #fff, 1538px 1447px #fff,
    1186px 874px #fff, 1967px 640px #fff, 1341px 19px #fff, 29px 1732px #fff,
    16px 1650px #fff, 1021px 1075px #fff, 723px 424px #fff, 1175px 41px #fff,
    494px 1957px #fff, 1296px 431px #fff, 175px 1507px #fff, 831px 121px #fff,
    498px 1947px #fff, 617px 880px #fff, 240px 403px #fff;
}

@mixin starsThreeAfter {
  content: "";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background: transparent;
  box-shadow: 387px 1878px #fff, 760px 1564px #fff, 1487px 999px #fff,
    948px 1828px #fff, 1977px 1001px #fff, 1284px 1963px #fff, 656px 284px #fff,
    1268px 1635px #fff, 1820px 598px #fff, 642px 1900px #fff, 296px 57px #fff,
    921px 1620px #fff, 476px 1858px #fff, 658px 613px #fff, 1171px 1363px #fff,
    1419px 283px #fff, 1037px 731px #fff, 503px 663px #fff, 1562px 463px #fff,
    383px 1197px #fff, 1171px 1233px #fff, 876px 1768px #fff, 856px 1615px #fff,
    1375px 1924px #fff, 1725px 918px #fff, 952px 119px #fff, 768px 1212px #fff,
    992px 1462px #fff, 1929px 717px #fff, 1947px 755px #fff, 1818px 1123px #fff,
    1896px 1672px #fff, 460px 198px #fff, 256px 271px #fff, 752px 544px #fff,
    1222px 1859px #fff, 1851px 443px #fff, 313px 1858px #fff, 709px 446px #fff,
    1546px 697px #fff, 674px 1155px #fff, 1112px 130px #fff, 355px 1790px #fff,
    1496px 974px #fff, 1696px 480px #fff, 1316px 1265px #fff, 1645px 1063px #fff,
    1182px 237px #fff, 427px 1582px #fff, 859px 253px #fff, 458px 939px #fff,
    1517px 1644px #fff, 1943px 60px #fff, 212px 1650px #fff, 966px 1786px #fff,
    473px 712px #fff, 130px 76px #fff, 1417px 1186px #fff, 909px 1580px #fff,
    1913px 762px #fff, 204px 1143px #fff, 1998px 1057px #fff, 1468px 1301px #fff,
    144px 1676px #fff, 21px 1601px #fff, 382px 1362px #fff, 912px 753px #fff,
    1488px 1405px #fff, 802px 156px #fff, 174px 550px #fff, 338px 1366px #fff,
    1197px 774px #fff, 602px 486px #fff, 682px 1877px #fff, 348px 1503px #fff,
    407px 1139px #fff, 950px 1400px #fff, 922px 1139px #fff, 1697px 293px #fff,
    1238px 1281px #fff, 1038px 1197px #fff, 376px 1889px #fff,
    1255px 1680px #fff, 1008px 1316px #fff, 1538px 1447px #fff,
    1186px 874px #fff, 1967px 640px #fff, 1341px 19px #fff, 29px 1732px #fff,
    16px 1650px #fff, 1021px 1075px #fff, 723px 424px #fff, 1175px 41px #fff,
    494px 1957px #fff, 1296px 431px #fff, 175px 1507px #fff, 831px 121px #fff,
    498px 1947px #fff, 617px 880px #fff, 240px 403px #fff;
}
