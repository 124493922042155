@use '../../../styles/partials/global';
.sidebar {
  overflow: hidden;
  @include global.breakpoint(desktop) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%s;
    max-width: 1500px;
    height: 0px;
  }
  &__sidebar-container {
    margin: 0;
    width: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    display: none;
    overflow: hidden;
    @include global.breakpoint(desktop) {
      width: 100%;
      height: 95px;
      top: 0;
      background-color: transparent;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      box-shadow: none;
    }

    &--active {
      @include global.breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100vh;
        top: 0;
        bottom: 0;
        margin: 0;
        z-index: 3;
        width: 100%;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
      }
      @include global.breakpoint(tablet) {
        width: 385px;
      }
      @include global.breakpoint(desktop) {
        width: 100%;
        height: 95px;
        background: none;
        margin: 0;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
      }
    }
  }
  &__list {
    background: linear-gradient(
        217deg,
        rgba(76, 243, 198, 0.8),
        rgba(10, 162, 231, 0) 70.71%
      ),
      linear-gradient(336deg, #0aa2e7, rgba(76, 243, 198, 0.4) 90.71%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 780px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.8);

    @include global.breakpoint(desktop) {
      flex-direction: row;
      justify-content: flex-end;
      height: 95px;
      min-height: 95px;
      box-shadow: none;
      background: none;
    }
    &-item {
      position: relative;
      margin: 0 0 30px 0;

      @include global.breakpoint(desktop) {
        margin: 0 30px 0 0;
      }

      &-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 240px;
        cursor: pointer;
        text-decoration: none;
        @include global.breakpoint(desktop) {
          text-decoration: none;
          width: -moz-fit-content;
          width: fit-content;
          justify-content: flex-end;
        }
      }
    }
    &-home,
    &-about,
    &-skills,
    &-work,
    &-contact {
      fill: #444649;
      @include global.breakpoint(desktop) {
        display: none;
      }
    }
    &-home-active,
    &-about-active,
    &-skills-active,
    &-work-active,
    &-contact-active {
      fill: #ffffff;
      @include global.breakpoint(desktop) {
        display: none;
      }
    }
  }
  &__hover-home,
  &__hover-about,
  &__hover-skills,
  &__hover-projects,
  &__hover-contact {
    font-family: 'MontserratSB';
    display: flex;
    font-size: 30px;
    font-weight: bold;
    padding: 0 0 0 30px;
    margin: 0;
    text-shadow: none;
    letter-spacing: 1px;
    &:hover {
      text-shadow: none;
    }

    @include global.breakpoint(desktop) {
      align-self: flex-end;
      font-size: 16px;
      padding: 0;
      &:hover {
        color: #ffffff;
      }
    }
  }
  &__backdrop {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 95px;
    left: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    @include global.breakpoint(desktop) {
      display: none;
    }
  }
  &__backdrop-false {
    display: none;
  }
}
